import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const StylePage = ({ location }) => (
  <Layout>
    <Helmet
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        }]
      }
    />

    <div className="space-y-4">
      <div data-example-section className="mt-32 mb-8 px-8 md:px-24 lg:px-32">
        <div className="space-y-8">
          <h1 className="h1">Our Work</h1>
          <div className="h2">Building brands for what’s next.</div>
          <div className="h3">Our Work</div>
        </div>
      </div>
    </div>

  </Layout>
)

export default StylePage
